import React from 'react'
import HomeLayout from '../layouts/Home'

const Home = () => {
  return (
    <div>
      <HomeLayout/>
    </div>
  )
}

export default Home
