
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const CutomerService = () => {
    const [animation ,setAnimation] = React.useState(false);
    const navigate = useNavigate();
  const domRef = React.useRef();
  useEffect(() => {
      const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
    
              // Not possible to set it back to false like this:
              setAnimation(true);
              
              // No need to keep observing:
              observer.unobserve(domRef.current);
            }
      });
  
      observer.observe(domRef.current);
  
     // return () => observer.unobserve(domRef.current);
    }, []);

    const handledownload = ()=>{
       // using Java Script method to get PDF file
       fetch("Orshana - Product Care.pdf").then((response) => {
        response.blob().then((blob) => {
         
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Orshana - Product Care.pdf";
            alink.click();
        });
    });
    }
  return (
    <div ref={ domRef }>
  <div >
  <p className='flex md:block justify-center md:justify-start items-center md:items-start mt-[5em] text-[#BEAA8E] text-[8px] md:text-[20px] '> CUSTOMER SERVICE</p>
    </div>
    <div className={`flex md:block justify-center md:justify-start items-center md:items-start  text-[18px] md:text-[50px]  font-Marcellus ${animation ? "fade-in-text" : "opacity-0"}`}>
     {/* text-center and text-left is not reflecting here according to screen */}
        <div className='footer-section' >
        <p onClick={()=>{navigate("/contactUs")}}>FAQS</p>
         <p onClick={()=>{navigate("/contactUs")}}>Contacts</p>
         <p onClick={handledownload} className='cursor-pointer'>Product Care</p>
         <p onClick={()=>{navigate("/careers")}}>Careers</p>
         <p onClick={()=>{navigate("/aboutUs")}}>About</p>
         <p onClick={()=>{navigate("/contactUs")}}>Customer Support</p>
         </div>
    </div>
  </div>
  )
}

export default CutomerService
