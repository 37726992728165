import React from 'react'
import { ShimmerThumbnail } from "react-shimmer-effects";
const Shimmer2 = () => {
  return (
    <div className='flex gap-5 justify-center items-center    '>
    <ShimmerThumbnail width={550} height={250} rounded />
   
    </div>
  )
}

export default Shimmer2
