import React ,{useState ,useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Closets from "../../assets/images/SwiperOne/Closets.jpg";
import LivingRooms from "../../assets/images/SwiperOne/Living Rooms.jpg"
import DinningRooms from "../../assets/images/SwiperOne/Dinning Rooms.jpg"
import Bathrooms from "../../assets/images/SwiperOne/Bathrooms.jpg"
import Hallways from "../../assets/images/SwiperOne/Hallways.jpg"
import Bedrooms from "../../assets/images/SwiperOne/Bedrooms.jpg"
import  Offices from "../../assets/images/SwiperOne/Offices.jpg";
import { Pagination, Navigation  } from 'swiper/modules';
import Shimmer from '../LoaderComponent/Shimmer';

const SlideShowOne = () => {
  //content to be shown in swiper
    const slides=[
    {
      title:"Living Rooms",
      src:LivingRooms
    },
    {
      title:"Dining Rooms",
      src:DinningRooms
    },
    {
    title:"Bathrooms",
    src:Bathrooms
    },
    {
      title:"Hallways",
      src:Hallways
    },
    {
      title:"Bedrooms",
      src:Bedrooms
    },
    {
      title:"Closets",
      src:Closets
    },
    {
      title:"Offices",
      src:Offices
    }
     
    ]

    const [imgsLoaded, setImgsLoaded] = useState(false)

  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image.src
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.src)
          }, 2000)

        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(slides.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [])
    return (
        <>
        <div className='mb-2 mt-20 '>
        <p className='flex justify-center items-center ] text-[#BEAA8E] text-[8px] md:text-[20px]'>NEW COLLECTIONS</p>
        </div>
   
          <Swiper
           
            slidesPerView={1.5}
            centeredSlides={true}
            spaceBetween={50}
            rewind={true}
            navigation={true}
           
            modules={[Pagination, Navigation]}
            className="mySwiperOne"
          >
    {slides.map((slide)=><SwiperSlide  ><div className=" flex gap-10    flex-col md:flex-col font-Marcellus text-[18px] md:text-[50px] cursor-pointer"><div className='flex justify-center items-center'><h1>{slide.title}</h1></div><div>{imgsLoaded?<img className="transition hover:scale-105 " src={slide.src}/>:<Shimmer/>}</div></div></SwiperSlide>)}
          </Swiper>
         
        </>
      );
}

export default SlideShowOne
