import React, { useState , useEffect } from 'react'
import AboutUsHeader from '../components/AboutUsComponent/AboutUsHeader'
import OurHeritage from '../components/AboutUsComponent/OurHeritage'
import WorldClassTeam from '../components/AboutUsComponent/WorldClassTeam'
import Footer from '../components/FooterComponent/Footer'
import Navbar from '../components/NavbarSeparate/Navbar'
import FullVideoLower from '../components/AboutUsComponent/FullVideoLower'
import OurCraftmanship from '../components/AboutUsComponent/OurCraftmanship'
import AboutUsSection3 from "../components/AboutUsComponent/AboutUsSection3";


const AboutUs = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <>
  <Navbar/>
   <div className='' >
    <AboutUsHeader/>
    <OurHeritage/>
    <WorldClassTeam/>
    <AboutUsSection3/>
    <FullVideoLower />
   <Footer/>
   </div>
    
    </>
    
  )
}

export default AboutUs