import React, { useEffect } from 'react';
import AccordionComp from '../components/ContactUsComponent/AccordionComp';
import ContactHome from '../components/ContactUsComponent/ContactHome';
import Navbar from '../components/NavbarSeparate/Navbar';
import Location from '../components/FooterComponent/Location';
import Contactoptions from '../components/ContactUsComponent/Contactoptions';
import locationImg from '../assets/contactus/Group 7.png'
import Footer from '../components/FooterComponent/Footer';

const ContactUs = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
  return (
    <>
      <Navbar />
      <ContactHome />
      <div className="m-4 sm:m-6 md:m-10">
        <Contactoptions />
      </div>
      <div className="m-4 sm:m-6 md:m-10 font ">
        <div className="text-[18px] md:text-[50px] font-Marcellus text-center mb-4">
          Got Questions?
        </div>
        <AccordionComp />
      </div>
      <div className="mt-10 md:mt-20 flex flex-col items-center text-black z-10">
        <div className="text-[18px] md:text-[50px] font-Marcellus text-center mb-4">
          Visit Us
        </div>
        <div className="mb-4 flex flex-col md:flex-row items-center">
          <img
            src={locationImg}
            alt="Location"
            className="w-6 md:w-8 lg:w-10 hidden lg:block"
          />
          <span className="ml-0 md:ml-2 text-center md:text-left font-Marcellus">
            101, T&G Avenue Building, Al Manara Street, SZR, Dubai, UAE
          </span>
        </div>
      </div>
      <Location />
      <Footer/>
    </>
  );
};

export default ContactUs;

