import React from "react";

const PopUpPolicy = (props) => {
    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                    <button
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                        onClick={props.togglePopup}
                    >
                        &times;
                    </button>
                    <div className="max-h-96 overflow-y-auto">
                        <h2 className="mb-4 text-1xl font-bold">*Welcome to Orshana's Website*</h2>
                        <p className="mb-4">Welcome to Orshana's website, located at the URL <span className="text-blue-500">www.orshana.com </span>(the "website"). Please take the time to review the following Privacy Policy, which outlines how we collect, use, disclose, and transfer the personal information you provide to us via Orshana's website, during visits to Orshana stores, when contacting Orshana Customer Services by telephone, or when interacting with us via social media. This policy also explains how we gather information through the use of cookies and related technologies when you use the Orshana website.</p>
                        <p className="mb-4">We also recommend reading the 'Global Servicing Terms & Conditions' on the Orshana website, as it contains important information.</p>
                        <p className="mb-4">Please be aware that this Privacy Policy is governed by the laws of the United Arab Emirates (UAE).</p>
                        <h2 className="mb-4 text-1xl font-bold">*Updates to this Privacy Policy*</h2>
                        <p className="mb-4">We reserve the right to update this Privacy Policy at any time. Any changes will be published on the Orshana website.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Information You Provide to Us and How We Use It*</h2>
                        <p className="mb-4">The information we collect from you includes:</p>
                        <ul className="list-disc pl-5">
                            <li>Registration Information: When accessing certain areas of the Orshana website, you will need to register with us. During this process, you will be required to submit personal information such as your name, address, tax number, email address, and telephone number. By providing this information, you allow Orshana to provide you with the selected services. </li>
                            <li>Purchase Information: When making purchases online or by telephone, we collect information such as your purchase details and relevant payment information. We also collect personal information when you contact Orshana with queries, visit Orshana stores, or contact Orshana Customer Services by telephone. Please note that telephone calls may be recorded for security and training purposes.</li>
                            <li>Social Media Interaction: If you choose to interact with Orshana via social media platforms or other third-party platforms, we will collect the personal information you provide through that platform.</li>
                            <li>Automatically Collected Information: We automatically collect certain information about visitors to the Orshana website. Further details on this can be found in the 'Cookies Policy' section below.</li>
                            <li>Location Information: We collect information about your location to the extent that we provide any location services.</li>
                        </ul>
                        <p className="mb-4">We use the personal information we collect from you to:</p>
                        <ul className="list-disc pl-5">
                            <li>Manage and fulfill purchase and repair orders, facilitate delivery, and provide after-sales services.</li>
                            <li>Manage our accounts and records.</li>
                            <li>Handle your inquiries and requests.</li>
                            <li>Send service-related communications, including announcements and administrative messages.</li>
                            <li>Identify products and services we think you might be interested in based on your personal information.</li>
                            <li>Conduct market research to improve the services we provide.</li>
                            <li>Understand how the Orshana website is used to improve our website, services, and offerings.</li>
                        </ul>
                        <p className="mb-4">We process your personal information based on your consent, when necessary for the performance of a contract with you, or when needed for the legitimate interests of operating, protecting, and improving our business.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Cookies and Other Information We Automatically Collect*</h2>
                        <p className="mb-4">Orshana logs IP addresses and uses cookies and similar technologies to identify and tailor your experience on our website. These technologies provide us with information about how visitors access our website. Cookies are small text files that are downloaded to your computer, tablet, or mobile device when you visit a website or use an app. They help recognize your device as you navigate between web pages.</p>
                        <p className="mb-4">Orshana uses cookies to enhance the online experience of our visitors, understand how our website is used, and ensure that advertisements are relevant to you. Cookies cannot retrieve other data from your hard disk, transmit viruses, or identify and use email addresses.</p>
                        <p className="mb-4">The Orshana website places the following types of cookies:</p>
                        <ul className="list-disc pl-5">
                            <li>Navigation Cookies: Necessary for accessing services and features on our website.</li>
                            <li>Functional Cookies: Enhance a visitor's experience on the platform.</li>
                            <li>Analytical Cookies: Collect information about how visitors use our website for aggregated analysis.</li>
                            <li>Advertising Cookies: Deliver relevant advertisements and evaluate promotional effectiveness.</li>
                            <li>Social Cookies: Enable sharing of pages and content through third-party social media platforms.</li>
                        </ul>
                        <p className="mb-4">You can manage or delete cookies through your browser settings. Disabling certain cookies may affect website performance.</p>
                        <h2 className="mb-4 text-1xl font-bold">*IP Addresses*</h2>
                        <p className="mb-4">When you visit the Orshana website, we collect information about your device, including your IP address, operating system, login times, and browser type. This data helps us understand how visitors use our website and may be used for internal reporting purposes.</p>
                        <h2 className="mb-4 text-1xl font-bold">*About Advertising*</h2>
                        <p className="mb-4">Orshana uses third-party services to serve advertisements on third-party platforms after you leave our website. We may also use third-party analytics providers to evaluate and provide information about website usage. These advertisements are tailored based on your visits to our website and other platforms.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Location Services*</h2>
                        <p className="mb-4">Some services provided by Orshana may use location information available on mobile devices or apps, with your consent. You can choose to disable location services on your device if you no longer wish to use them.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Sharing Your Information*</h2>
                        <p className="mb-4">Orshana does not share personal information about you with non-affiliated companies, except in certain circumstances:</p>
                        <ul className="list-disc pl-5">
                            <li>Employees and consultants of Orshana processing data for internal organizational activities.</li>
                            <li>Other companies within the Orshana Group for contractual activities and services.</li>
                            <li>Third-party service providers for technical and organizational services related to the website.</li>
                            <li>Law enforcement agencies, courts, regulators, or government authorities where required by law.</li>
                            <li>Third parties in the event of a sale or transfer of assets and business.</li>
                        </ul>
                        <h2 className="mb-4 text-1xl font-bold">*International Transfers*</h2>
                        <p className="mb-4">We implement safeguards to protect your personal information when transferring it to affiliated companies and stores outside the European Economic Area.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Protecting Your Personal Information*</h2>
                        <p className="mb-4">Orshana is committed to protecting the personal information we collect. We limit access to your information to employees who require it for providing products or services. We have technical and organizational safeguards in place to protect against unauthorized processing, loss, damage, or destruction of personal information.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Retaining Your Personal Information*</h2>
                        <p className="mb-4">Personal information collected during purchases will be stored for at least 10 years to comply with tax and civil law. Information provided for marketing and profiling activities is kept as long as necessary for the particular processing.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Your Choices*</h2>
                        <p className="mb-4">You have the right to access, correct, update, or delete personal information we hold about you. You can object to Orshana processing your personal information in certain cases and request to stop receiving direct marketing communications.</p>
                        <h2 className="mb-4 text-1xl font-bold">*Contact Us*</h2>
                        <p className="mb-4">If you have questions about this Privacy Policy or your personal information, please contact us at <span className="text-blue-500">hello@orshana.com</span>.</p>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PopUpPolicy