import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/navbarimage.png'
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path  = location.pathname;
  const [show, setShow] =   useState(true);
  const [selected, setSelected] = useState(null);
  const  [scrollPosition , setScrollPosition] = useState(window.scrollY);
  console.log("window.scrollY",window.scrollY);
  const handleClick = (path) => {
    setSelected(path);
    navigate(path);
  };
  const controlNavbar = () => {
    if (window.scrollY > 50) { 
      //scroll down show navbar
      setShow(false); 
    } else { 
     //scroll up hide
      setShow(true);  
    }

    // // remember current page location to use in the next move
    // setLastScrollY(window.scrollY); 
  };
  useEffect(() => {
    if(path==="/home")
      {

      
    window.addEventListener('scroll', controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener('scroll', controlNavbar);
  }
 
 
 }
 else{
  setShow(false)
}
 
  }, [scrollPosition]);
  return (
    <nav id="navbar" className={`bg-white p-4 shadow fixed z-30 w-[100%] transition-opacity duration-500  hidden md:block ${show ? 'opacity-0' : 'fade-in-text'}    `}>
      <div className="container mx-auto flex items-center pr-4">
        <div className="flex items-center cursor-pointer" onClick={()=>handleClick("/home")}>
           <img className="w-1/4 md:w-32 z-10" src={logo} alt="logo orshana" />
        </div>
        <div className="hidden md:flex space-x-16 ml-12">
          <a href="/home" className=" p-2 rounded-lg text-black hover:text-gray-600">Concepts</a>
          <a href="/materials" className="p-2 rounded-lg text-black hover:text-gray-600">Materials</a>
          <a href="/aboutUs" className={`p-2 rounded-lg text-black hover:text-gray-600 ${path === '/aboutUs' ? 'bg-[#dde0d7cd]' : ''}`} onClick={() => handleClick('/aboutUs')}>About us</a>
          <a href="/showroom" className="p-2 rounded-lg text-black hover:text-gray-600">Showroom</a>
          <a href="/contactUs" className={`p-2 rounded-lg text-black hover:text-gray-600  ${path === '/contactUs' ? 'bg-[#dde0d7cd]' : ''}`} onClick={() => handleClick('/contactUs')}>Contact Us</a>
        </div>
      </div>
     
    </nav>
  );
};

export default Navbar;
