import React, { useState } from "react";

const KnowMoreAbout = () => {
    const [animation ,setAnimation] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
    
              // Not possible to set it back to false like this:
              setAnimation(true);
              
              // No need to keep observing:
              observer.unobserve(domRef.current);
            }
      });
  
      observer.observe(domRef.current);
  
     // return () => observer.unobserve(domRef.current);
    }, []);
  
  return (
    <>
            <div ref={ domRef }  className="flex justify-center items-center mb-8 mt-10 md:mt-40">
                <div className={`text-center text-[18px] md:text-[50px] font-Marcellus ${animation ? "fade-in-text" : "opacity-0"}`}>
                    <div>Orshana’s unique interior </div>
                    <div>lifestyle concept is inspired by</div>
                    <div>the world’s rich mix of </div>
                    <div>cultures and most magnificent</div>
                    <div>places.</div>
                </div>
            </div>
            <div className="flex justify-center items-center mb-16 mt-2">
            <button className=" py-2 px-4 text-[#BEAA8E] text-[30px] md:text-[40px] font-BritannySignature">
                        Know more about us
            </button>
            </div>
        </>
    )
}


export default KnowMoreAbout