import React from 'react'
import { ShimmerThumbnail } from "react-shimmer-effects";
const Shimmer = () => {
  return (
    <div className='flex gap-5 justify-center items-center    '>
    <ShimmerThumbnail width={990} height={470} rounded />
   
    </div>
  )
}

export default Shimmer
