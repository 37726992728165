import React from "react";
import FormComponent from "./FormComponent";

const ContactHome = () => {
    return (
        <>
        <div  className={`background-image-container-contact bg-cover bg-center w-full  hidden md:flex flex-col justify-center items-center   `}>
            {/* <div className="absolute inset-0 bg-black opacity-70 hidden md:block"></div> */}
            <div className="flex gap-40 flex-col md:flex-row justify-center items-center z-20   md:mt-[10%]">
            <div className="text-white text-[18px] md:text-[50px] font-Marcellus w-4/10 ">
                            <div>Get in touch with us</div>
                            <div>We deliver and</div>
                            <div>commission globally.</div>
            </div>
           
            <div className="w-6/10">
                <FormComponent/>
            </div>
            </div>
        </div>
        <div>
            
            <div className="md:hidden">
        <div className="flex flex-col md:flex-row justify-center items-center z-20 mt-20 ] ">
           <div className="background-image-container-contact w-full h-[80vh] flex justify-center items-center">
    <div className="text-white text-[18px] md:text-[50px] font-Marcellus w-4/10 text-center">
        <div>Get in touch with us</div>
        <div>We deliver and</div>
        <div>commission globally.</div>
    </div>
</div>

            <div className="w-6/10">
                <FormComponent/>
            </div>
            </div>
        </div>
        </div>
        </>
    );
};

export default ContactHome;
