import React, { useEffect, useState } from 'react'
import emailjs from 'emailjs-com';
import tickmark from "../../assets/images/tickmark.png"
const SERVICE_ID = "service_l0vlneq";
const TEMPLATE_ID = "template_ht07puj";
const PUBLIC_KEY = "ycV2K9smfkifkngG5";

const NewsLetter = () => {


  const [animation, setAnimation] = React.useState(false);
  const [senderEmail,setSenderEmail] = React.useState("")
  const  [showTag , setShowTag] = useState(false)
  const domRef = React.useRef();
  const [mandatory ,setMandatory] = useState("");
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {

        // Not possible to set it back to false like this:
        setAnimation(true);

        // No need to keep observing:
        observer.unobserve(domRef.current);
      }
    });

    observer.observe(domRef.current);

   // return () => observer.unobserve(domRef.current);
  }, []);
  const handleChange = (e) => {
    setMandatory(false)

    setSenderEmail(e.target.value); 
  };
useEffect(()=>{
showTag && setTimeout(()=>{setShowTag(false);
},[3000])
mandatory && setTimeout(()=>setMandatory(false),[3000])
},[showTag , mandatory])

const handleOnSend = () => {
  const email = senderEmail || '';
  const emailValid = email.match(validRegex);

  if (emailValid) {
    const emailParams = {
      to_email: 'hello@orshana.com',
      subject: 'New user connection',
      message: `A new user ${email} needs to connect with Orshana team`,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, emailParams, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        console.log('Message Sent Successfully');
      })
      .catch((error) => {
        console.error('Error:', error.text);
        console.log('Something went wrong!');
      });

    setSenderEmail('');
    setShowTag(true);
    setMandatory(false);
  } else if (!email) {
    setMandatory('Email is required');
  } else if (!emailValid) {
    setMandatory('Enter a valid email address');
  }
};


  return (
    <div ref={domRef} className='mt-10 md:mt-10'>
      <p className='flex justify-center items-center mt-[5em] text-[#BEAA8E] text-[8px] md:text-[20px] '>SUBSCRIBE TO OUR NEWSLETTER</p>
      <div className='flex justify-center items-center mt-2 text-[18px] md:text-[50px]'>
        <span className={`text-center font-Marcellus  ${animation ? "fade-in-text" : "opacity-0"}`}>
          <p>Subscribe to our Newsletter</p>
          <p>Get the latest Updates</p>
        </span>
      </div>
      <div className='flex flex-col gap-5 justify-center items-center mt-10'>
        <input
          type="email"
          placeholder="INSERT YOUR MAIL"
          className="block w-[20em] md:w-[30em] px-[20%] md:px-4 py-1 md:py-3 border border-black rounded-md focus:outline-none focus:border-gray-500 text-center font-[8px] md:font-[12px]"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          required
          value={senderEmail} 
          onChange={handleChange}
        />
        <div className="  px-6 md:px-10 py-2 md:py-2  bg-white border border-black rounded-lg font-bold text-black text-[10px] md:text-[12px] hover:bg-gray-200 transition duration-300 font-Montagu_Slab" onClick={handleOnSend}>Enter</div>
    {showTag &&  <div>
<span id="badge-dismiss-green" class="inline-flex items-center pl-2 py-1 text-sm font-medium text-green-800 bg-green-100 rounded dark:bg-green-900 dark:text-green-300">
Email Sent
<div  className="inline-flex items-center p-1 ms-2 text-sm text-green-400 bg-transparent rounded-sm " data-dismiss-target="#badge-dismiss-green" aria-label="Remove">
<img className="w-5"src={tickmark}/>
</div>
</span>
</div> }
{mandatory.length>0 && <p className="text-red-500 text-xs mt-1">{mandatory}</p>}
      </div>

    </div>
  )
}

export default NewsLetter
