import React from 'react'
import Video2 from "../../assets/AboutUs/aboutUsVideo2.mp4"

const OurHeritage = () => {
  const [animation ,setAnimation] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
  
            // Not possible to set it back to false like this:
            setAnimation(true);
            
            // No need to keep observing:
            observer.unobserve(domRef.current);
          }
    });

    observer.observe(domRef.current);

   // return () => observer.unobserve(domRef.current);
   //<div className='w-[70%] md:w-full aboutUsVideo  flex items-center justify-center'>
  }, []);
  return (
    <div  className='flex flex-col md:flex-row items-center justify-center  m-10 font-Marcellus md:mt-[-1rem] '>
        <div className='w-[70%] md:w-full aboutUsVideo  flex items-center justify-center'>
    <video className='pt-2 md:pt-10' disablePictureInPicture  autoPlay loop muted webkit-playsinline playsInline>
          <source src={Video2} type="video/mp4"></source>
        </video>
        </div>
        <div ref={domRef} className={`${animation ? "fade-in-text" : "opacity-0"} md:mr-10 fixed-width`} >
      <h2 className='flex flex-col md:flex-row items-center justify-left   text-[15px] md:text-[55px] mobView-header '>Our Heritage</h2>
      <br/>
      <p className='  text-left  mobView '>As peers driven by a passion for creativity and design, we founded Orshana
Bespoke Interiors to redefine bespoke interiors and craft living works of art.
With a foundation in innovation, craftsmanship, and dedication, Orshana
combines years of industry expertise to deliver tailor-made furniture and
interior solutions in the United Arab Emirates.</p>
<br/>
<p className=' text-left mobView '>Specializing in residential, hospitality, commercial, and industrial sectors, we
elevate spaces with unique designs that exceed expectations. At Orshana, we
believe every space tells a story, and it's our mission to bring those stories to
life, infusing each project with character and charm.</p>
<br/>
<p className=' text-left mobView '>With a commitment to excellence and lasting relationships, we invite you to
join us on this journey of bespoke design.</p>
<br/>
<p className='text-left mobView'>Trust us with your vision.
<br/>
Let's create something extraordinary together</p>
</div>
    </div>
  )
}

export default OurHeritage
