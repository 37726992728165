import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevron from "./chevron.svg";

/**
 * @type {React.ExoticComponent<import('@szhsin/react-accordion').AccordionItemProps>}
 */
const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`ml-auto transition-transform duration-200 ease-out ${
            isEnter && "rotate-180"
          }`}
          src={chevron}
          alt="Chevron"
        />
      </>
    )}
    className="border-b"
    buttonProps={{
      className: ({ isEnter }) =>
        `flex w-full p-4 text-left hover:bg-slate-100 ${
          isEnter && "bg-slate-200"
        }`
    }}
    contentProps={{
      className: "transition-height duration-200 ease-out"
    }}
    panelProps={{ className: "p-4" }}
  />
);

export default function AccordionComp() {

const faqJSon= [
  {
    "Question": "How do you determine the cost of a project?",
    "Answer": "The cost of a project depends on various factors, including the scope of work, materials used, and the complexity of the design. During the initial consultation, we discuss your budget and provide a preliminary estimate. As the design evolves, we offer a detailed proposal outlining all costs associated with the project."
  },
  {
    "Question": "Can Orshana Bespoke Interiors work within my budget?",
    "Answer": "Yes, we pride ourselves on creating beautiful, functional spaces within a wide range of budgets. We work closely with you to understand your financial constraints and offer creative solutions to achieve the desired look and feel without compromising on quality."
  },
  {
    "Question": "How long does it typically take to complete a project?",
    "Answer": "The timeline for each project varies based on its size and complexity. A typical residential project can take anywhere from a few weeks to several months. During the initial consultation, we provide an estimated timeline and keep you informed throughout the project to ensure timely completion."
  },
  {
    "Question": "Do you offer eco-friendly design options?",
    "Answer": "Absolutely! At Orshana Bespoke Interiors, we are committed to sustainability and offer a range of eco-friendly design options. We source environmentally friendly materials and work with suppliers who prioritize sustainable practices. We also incorporate energy-efficient lighting and appliances into our designs."
  },
  {
    "Question": "Can I see examples of your previous work?",
    "Answer": "Yes, we have a portfolio showcasing a variety of completed projects. You can view our portfolio on our website or request a personalized presentation during your consultation. We also have testimonials from satisfied clients who can attest to our high standards of design and service."
  },
    {
    "Question": "What if I need help with only one aspect of my interior design project?",
    "Answer": "We offer flexible services to meet your specific needs. Whether you need help with space planning, furniture selection, or color consultation, we can provide tailored solutions for individual aspects of your project. Contact us to discuss your requirements, and we'll create a customized plan for you."
  },
  {
    "Question": "How do you handle project changes or modifications?",
    "Answer": "We understand that changes may arise during the design process. We maintain open communication with our clients and are flexible in accommodating modifications. Any changes are discussed and agreed upon before proceeding, ensuring that the project stays on track and within budget."
  },   {
    "Question": " Do you offer worldwide delivery and installation services?",
    "Answer": "Yes, Orshana Bespoke Interiors offers worldwide delivery and installation services. We coordinate logistics to ensure that your bespoke furniture and design elements are delivered and installed wherever you are located. Our global network of trusted partners ensures that you receive the same high-quality service no matter your location."
  },   {
    "Question": "How do I get started with Orshana Bespoke Interiors?",
    "Answer": "To get started, simply contact us through our website, email, or phone to schedule an initial consultation. During this meeting, we’ll discuss your vision, needs, and preferences. From there, we’ll develop a customized design plan tailored to your unique requirements. We look forward to helping you create the space of your dreams!"
  },
]

  return (
    <div className="mx-2 my-4 border-t  font-Marcellus text-lg">
      {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
      <Accordion transition transitionTimeout={200}>
      <AccordionItem header={"What services does Orshana Bespoke Interiors offer?"}  >
        <p className="text-sm">
        Orshana Bespoke Interiors specializes in custom interior design solutions for residential and commercial spaces. Our services include space planning, furniture design, color and material consultation, lighting design, project management, and installation services. We tailor our approach to meet the unique needs of each client, ensuring a personalized and high-quality design experience.
        </p> 
        </AccordionItem>
      <AccordionItem header="How does the design process work at Orshana Bespoke Interiors?">
        <p className="text-sm"> 
        Our design process typically involves several key steps:
        <br/>
- <span className="font-bold">Initial Consultation:</span> We start with a meeting to discuss your vision, needs, and budget.
<br/>
- <span className="font-bold">Concept Development:</span> We create design concepts and mood boards to visualize the project.
<br/>
- <span className="font-bold">Design Presentation:</span> We present detailed design plans, including 3D renderings, materials, and color schemes.
<br/>
- <span className="font-bold">Revision and Approval:</span> We refine the designs based on your feedback.
<br/>
- <span className="font-bold">Implementation:</span> We manage the procurement, construction, and installation phases to bring the design to life.
<br/>
- <span className="font-bold">Final Review:</span> We conduct a walkthrough to ensure everything meets your satisfaction.
      </p> 
        </AccordionItem>
     {faqJSon.map((json)=> <AccordionItem header={json.Question}  >
        <p className="text-sm">
           {json.Answer}
        </p> 
        </AccordionItem>
)}  
       

      
      </Accordion>
    </div>
  );
}
