import React, { useState } from 'react'
import GetInTouch from "../../assets/images/Get in Touch.png";
import NewsLetter from './NewsLetter';
import CutomerService from './CutomerService';

const Footer = () => {

  
  return (
    <>
<div className=" mt-[3em] md:mt-[10em] mb-[5em] " >
  <div className='flex justify-center items-center '>
    <h1 className='text-[#BEAA8E] text-[50px] md:text-[90px]  font-BritannySignature'>Get In Touch</h1>
  </div>
 <div className='block md:flex justify-evenly gap-10 '>
  <CutomerService/>
  <NewsLetter/>
 </div>
  </div>

  </>
  )
}

export default Footer
