import React from 'react'

const FullVideoLower = () => {
  const video6="https://firebasestorage.googleapis.com/v0/b/orshana-images.appspot.com/o/Orshana%20Craftsmanship.mp4?alt=media&token=306d523f-7ccb-46b8-a54c-d49e8f2f71f1";
  return (
    <div className='flex justify-center' >
      <div className='' > 
              <h2 className='flex flex-col md:flex-row items-center justify-center  text-[15px] md:text-[50px] mt-2 font-Marcellus '>Witness the Allure of Handcrafted Furniture</h2>
       <video className='md:mt-5 '  disablePictureInPicture playsinline autoPlay loop muted webkit-playsinline playsInline>
          <source src={video6} type="video/mp4"></source>
        </video>
        </div>
    </div>
  )
}

export default FullVideoLower
