import React from "react";
import { BrowserRouter as Router, Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Hamburger from "./components/NavbarSeparate/Hamburger";
import ContactUs from "./pages/ContactUs";
import Opening from "./pages/Opening";


function App() {

  return (
    <>
   
      <BrowserRouter>
      <Hamburger/>
        <Routes>
        <Route path="/" element={<Opening/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/aboutUs" element={<AboutUs/>} />
          <Route path="/contactUs" element={<ContactUs/>} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>

      </BrowserRouter>
    </>
  );
}

export default App;
