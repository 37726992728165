import React, { useState } from 'react'
import Video1 from "../../assets/AboutUs/aboutUsVideo1.mp4"

const ContactHeaderVideo = () => {
  const [animation ,setAnimation] = useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
  
            // Not possible to set it back to false like this:
            setAnimation(true);
            
            // No need to keep observing:
            observer.unobserve(domRef.current);
          }
    });

    observer.observe(domRef.current);

   // return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <>
    <div className='flex justify-center  pt-[10%] md:pt-[8%] font-Marcellus'>
        <div className='flex-col'>
        <div className='flex  justify-center '>
        <h2  className='text-[18px] md:text-[20px]  mt-2 md:mt-10'>ABOUT ORSHANA</h2>
        </div>
        <div className='flex  justify-center'>
        <p className='text-[18px] md:text-[50px]'> Experience our Artistry in Design</p>
        </div>   
        </div>
        </div>
    <div ref={domRef} className={`flex items-center justify-center ml-[10%] md:ml-[20%] max-h-full md:h-[20%] max-w-full md:w-[60%]  ${animation ? "fade-in-text" : "opacity-0"} `}>
        <div >
         <video   disablePictureInPicture  autoPlay loop muted webkit-playsinline playsInline>
          <source src={Video1} type="video/mp4"></source>
        </video>
        </div>
    </div>
    
    </>
  )
}

export default ContactHeaderVideo
