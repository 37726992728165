import React, { useState } from 'react';
import countries from "./countries.json";
import emailjs from 'emailjs-com';
import PopUpPolicy from './PopUpPolicy';
const FormComponent = () => {
  const SERVICE_ID = "service_l0vlneq";
const TEMPLATE_ID = "template_ht07puj";
const PUBLIC_KEY = "ycV2K9smfkifkngG5";
  // State to hold form input values
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phonenumber: '',
    country: '',
    message: '',
    agreement: false // Example for checkbox agreement
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phoneno: false,
    country: false,
    message: false,
    agreement: false
  });
  const emailParams = { to_email:'hello@orshana.com',subject:"New user connection",message:`A new user ${formData.name} need to connect with Orshana team.Here are the details:
     
    name: ${formData.name},
    email: ${formData.email},
    phonenumber: ${formData.phonenumber},
    country: ${formData.country},
    message: ${formData.message}
  `};

  const handleOnSend = () => {
    emailjs.send(SERVICE_ID, TEMPLATE_ID,emailParams, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        console.log('Message Sent Successfully');
      }, (error) => {
        console.log(error.text);
        console.log('Something went wrong!');
      });
    setFormData({
      name: '',
      email: '',
      phonenumber: '',
      country: '',
      message: '',
      agreement: false // Example for checkbox agreement
    })
    
  };
  // Function to handle input changes
  const handleInputChange = (e) => {
  
    const { id, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let hasErrors = false;

    // Check if all fields are filled
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = true;
        hasErrors = true;
     
      } else {
        errors[key] = false;
        
      }
    });
    setFormErrors(errors);
    // Update state to display errors if any
    

    // If no errors, proceed with form submission
    if (!hasErrors) {
 
     handleOnSend();
     
    } else {
      console.log("Form has errors. Please fill all required fields.");
    }
  };
  return (
    <> {
      isOpen && (<PopUpPolicy togglePopup={togglePopup} />)

    }
    <div className="flex items-center justify-center pb-10 m-[2%] md:m-0">
      <form className="bg-white p-4 rounded-lg w-full max-w-md" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            className={`w-full p-2 border ${formErrors.name ? 'border-red-500' : 'border-gray-300'} rounded`}
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {formErrors.name && <p className="text-red-500 text-xs mt-1">Name is required</p>}
        </div>
        <div className="mb-4 flex flex-col md:flex-row md:gap-2">
          <div className="flex-1 mb-4 md:mb-0">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              className={`w-full p-2 border ${formErrors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {formErrors.email && <p className="text-red-500 text-xs mt-1">Email is required</p>}
          </div>
          <div className="flex-1">
            <label htmlFor="phoneno" className="block text-gray-700 mb-2">
              Phone
            </label>
            <input
              type="text"
              id="phonenumber"
              className={`w-full p-2 border ${formErrors.phonenumber ? 'border-red-500' : 'border-gray-300'} rounded`}
              placeholder="Enter your Phone No"
              value={formData.phonenumber}
              onChange={handleInputChange}
            />
            {formErrors.phonenumber && <p className="text-red-500 text-xs mt-1">Phone is required</p>}
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="country" className="block text-gray-700 mb-2">
            Country
          </label>
          <select
            id="country"
            className={`bg-gray-50 border ${formErrors.country ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-black focus:border-[#826B49] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            value={formData.country}
            onChange={handleInputChange}
          >
            <option value="">Choose a country</option>
            {countries.map((country) => (
              <option key={country.value} value={country.value}>
                {country.name}
              </option>
            ))}
          </select>
          {formErrors.country && <p className="text-red-500 text-xs mt-1">Country is required</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 mb-2">
            Message
          </label>
          <textarea
            id="message"
            className={`w-full p-2 border ${formErrors.message ? 'border-red-500' : 'border-gray-300'} rounded resize-none`}
            placeholder="Enter your message"
            rows="4"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
          {formErrors.message && <p className="text-red-500 text-xs mt-1">Message is required</p>}
        </div>
        <div className="flex md:flex-col items-center p-4 bg-gray-100 justify-center mb-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="agreement"
              className="form-checkbox h-5 w-5 text-blue-600"
              checked={formData.agreement}
              onChange={handleInputChange}
            />
            <span className="text-blue-400" onClick={togglePopup}>I have read and agree to Orshana's Privacy Policy</span>
          </label>
          {formErrors.agreement && <p className="text-red-500 text-xs mt-1">You must agree to the Privacy Policy</p>}
        </div>
      
        <button type="submit" className="w-full p-2 bg-[#826B49] text-white rounded hover:bg-[#5B4A33]">
          SEND
        </button>
      </form>
    </div>
    </>
  );
};

export default FormComponent;
