import React from 'react'
import  logo from "../../assets/images/logo.png"
const Logo = () => {
  return (
    <div className='flex justify-center items-center mt-[2em] md:mt-[10em]'>
            <img className=" w-[40%] md:w-[15%] " src={logo} alt="logo" />
    </div>
  )
}

export default Logo
