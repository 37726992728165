import React ,{useState ,useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import outdoor from "../../assets/images/SwiperTwo/a.jpg";
import accessories from "../../assets/images/SwiperTwo/accessories.jpg";
import lightings from "../../assets/images/SwiperTwo/lighting.jpg";
import rugs from "../../assets/images/SwiperTwo/rugs.jpg";
import furniture from "../../assets/images/SwiperTwo/furniture.jpg";
import upholstery from "../../assets/images/SwiperTwo/upholstery.jpg";
import { Pagination, Navigation  } from 'swiper/modules';
import Shimmer2 from '../LoaderComponent/Shimmer2';

const SlideshowSecond = () => {
   //content to be shown in swiper
    const slides=[
        {src:outdoor,
      title:"Outdoor"},
     
{src:upholstery,
title:"Upholstery"},
      
{src:furniture,
title:"Furniture"},
     
{src:lightings,
title:"Lightings"},

{src:accessories,
title:"Accessories"},
{src:rugs,
  title:"Rugs"},
    ]
    const [imgsLoaded, setImgsLoaded] = useState(false);
    
  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image.src
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.src)
          }, 2000)

        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(slides.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [])
    return (
        <>
        <div className='mb-2 mt-20 '>

          <Swiper
             pagination={{
              type:"progressbar"
            }}
            slidesPerView={2.5}
            centeredSlides={true}
            spaceBetween={50}
            rewind={true}
            navigation={true}
            modules={[ Navigation]}
            className="mySwiperSecond"
          >
            {slides.map((slide)=> 
            <SwiperSlide   ><div className="flex   flex-col-reverse md:flex-col-reverse font-Marcellus text-[18px] md:text-[50px] cursor-pointer "><div><h1 className='flex justify-center items-center mt-4 md:mt-8'>{slide.title}</h1></div><div >{imgsLoaded ? <img className="img1 transition hover:scale-110 " src={slide.src}/>:<Shimmer2/>}</div></div></SwiperSlide>)}
             
          </Swiper>
          <div className="flex justify-center items-center mt-3 mb-1 md:mb-16">
            <button className=" py-2 px-4 text-[#BEAA8E] text-[30px] md:text-[40px] font-BritannySignature">
                        Know More
            </button>
            </div>
            </div>
        </>
      );
};

export default SlideshowSecond;
