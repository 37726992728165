import React, { useState } from "react";
import frame7 from '../../assets/images/Frame 7.png';
import frame8 from '../../assets/images/Frame 8.png';
import frame9 from "../../assets/images/Frame 9.png";
import orshanalogo from "../../assets/images/ORSHANA LOGO 1.png"

const HeritageAndRefinment= () => {
    const [animation ,setAnimation] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
    
              // Not possible to set it back to false like this:
              setAnimation(true);
              
              // No need to keep observing:
              observer.unobserve(domRef.current);
            }
      });
  
      observer.observe(domRef.current);
  
     // return () => observer.unobserve(domRef.current);
    }, []);
  
  return (
    <>
<div ref={ domRef } >
            <div className="flex justify-center items-center mb-8 mt-[3em] md:mt-[9em]" >
                <div className={`text-center text-[18px] md:text-[50px] font-Marcellus  ${animation ? "fade-in-text" : "opacity-0"}`}>
                    <div>Heritage And </div>
                    <div>Refinement at its peak...</div>
                </div>
            </div>
            <div className="flex gap-5 flex-col md:flex-row justify-center items-center md:items-end mb-16 mt-20" >
                <div className={`scale-x-100 scale-y-100  ${animation ? "zoom-in-out-box  scale-x-95 scale-y-95" : ""}`}>
                    <img src={frame7} alt="frame7" className="w-64 md:w-[377px]  md:w-84 h-84 md:h-84  mb-2" />
                </div>
                <div  className={`scale-x-100 scale-y-100  ${animation ? "zoom-in-out-box  scale-x-95 scale-y-95" : ""} flex flex-col items-center ml-0 md:ml-4`}>
                    <img src={frame8} alt="frame8" className="w-64 md:w-[377px] md:w-84 h-84 md:h-84  mb-2" />
                </div>
                <div  className={`scale-x-100 scale-y-100  ${animation ? "zoom-in-out-box  scale-x-95 scale-y-95" : ""} flex flex-col items-center ml-0 md:ml-4`}>
                    <img src={frame9} alt="frame9" className="w-64 md:w-[377px] md:w-84 h-84 md:h-84  mb-2" />
                </div>
            </div>
            </div>
        </>
    )
}


export default HeritageAndRefinment