import React, { useEffect, useState } from "react";
import logo from "../../assets/images/4.png";
import { useNavigate } from "react-router-dom";

function OpeningPage() {
  const [showVideo, setShowVideo] = useState(false);
  const [mute, setMute] = useState(true);
  const navigate= useNavigate();
  const [animation ,setAnimation] = React.useState(false);

  const domRef = React.useRef();
  useEffect(() => {
    // Delay setting the showVideo state to true to ensure the fade-in effect is visible
    const timeout = setTimeout(() => {
      setShowVideo(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);
useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
  
            // Not possible to set it back to false like this:
            setAnimation(true);
            
            // No need to keep observing:
            observer.unobserve(domRef.current);
          }
    });

    observer.observe(domRef.current);

   // return () => observer.unobserve(domRef.current);
  }, []);
const redirectFunc =()=>{
navigate("/home")
}
  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/orshana-images.appspot.com/o/Orshana%20Landing%20Page%20-%20Video%20Only.mp4?alt=media&token=3fa2b446-6219-4fa0-9281-27b4d7696bee";
  return (
    <div className="w-full h-screen overflow-hidden">
      <div ref={ domRef } className={`${animation ? "fade-in-text" : "opacity-0"}`} >
        <div className="absolute bottom-[30%] left-[30%] md:left-[16%] text-white z-10">
          <div className="absolute bottom-[35%] md:bottom-[30%] transform translate-y-1/2 z-10">
            <p className="custom-font text-center">
              Get in Touch with us at:
              <br />
              +97143213615
              <br />
              hello@orshana.com
            </p>
            <button 
      className={`custom-font bg-[#7a6c59] text-white font-bold p-2 ml-[0.4rem] rounded hover:bg-[#BEAA8E]  {
            showVideo ? "show" : ""
          }`}
      onClick={redirectFunc}
    >
      Visit our website
    </button>
          </div>
        </div>
        <img
          className=" absolute bottom-[43%] md:bottom-[37%] lg:bottom-[40%] left-[23%] md:left-[10%] lg:left-[6%] w-[50%] md:w-[30%] z-20 "
          src={logo}
        />
        <video
          src={videoUrl}
          className={`absolute inset-0 w-full h-full object-cover fade-in ${
            showVideo ? "show" : ""
          } `}
          autoPlay
          muted={mute}
          disablePictureInPicture 
          loop ={true}
          playsInline={true}
          webkit-playsinline
        />
      </div>

      <div>
        {!mute ? (
          <svg
            className="absolute bottom-4 right-4 z-10 bg invert"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 480 480"
            width="30"
            height="30"
            onClick={() => setMute((mute) => !mute)}
          >
            <path d="m278.944 17.577c-5.568-2.656-12.128-1.952-16.928 1.92l-155.648 124.512h-74.368c-17.632 0-32 14.368-32 32v128c0 17.664 14.368 32 32 32h74.368l155.616 124.512c2.912 2.304 6.464 3.488 10.016 3.488 2.368 0 4.736-.544 6.944-1.6 5.536-2.656 9.056-8.256 9.056-14.4v-416c0-6.144-3.52-11.744-9.056-14.432z" />
            <path d="m368.992 126.857c-6.304-6.208-16.416-6.112-22.624.128-6.208 6.304-6.144 16.416.128 22.656 24.192 23.872 37.504 55.968 37.504 90.368s-13.312 66.496-37.504 90.368c-6.272 6.176-6.336 16.32-.128 22.624 3.136 3.168 7.264 4.736 11.36 4.736 4.064 0 8.128-1.536 11.264-4.64 30.336-29.856 47.008-70.048 47.008-113.088s-16.672-83.232-47.008-113.152z" />
            <path d="m414.144 81.769c-6.304-6.24-16.416-6.176-22.656.096-6.208 6.272-6.144 16.416.096 22.624 36.384 36.064 56.416 84.192 56.416 135.52s-20.032 99.424-56.416 135.488c-6.24 6.24-6.304 16.384-.096 22.656 3.168 3.136 7.264 4.704 11.36 4.704 4.064 0 8.16-1.536 11.296-4.64 42.496-42.08 65.856-98.272 65.856-158.208s-23.36-116.128-65.856-158.24z" />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
          </svg>
        ) : (
          <svg
            version="1.1"
            id="Capa_1"
            className="absolute bottom-4 right-4 z-10 invert"
            x="0px"
            y="0px"
            viewBox="0 0 480 480"
            width="30"
            height="30"
            onClick={() => setMute((mute) => !mute)}
          >
            <g>
              <g>
                <path
                  d="M342.94,1.591c-5.6-2.688-12.128-1.92-16.928,1.92l-127.68,102.08c-6.912,5.504-8,15.584-2.496,22.496
                c5.536,6.944,15.584,8.064,22.496,2.528l101.664-81.312v196.48c0,8.864,7.168,16,16,16c8.832,0,16-7.168,16-16V16.023
                C351.996,9.879,348.476,4.279,342.94,1.591z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M443.324,420.727l-95.968-95.968l-0.032-0.032l-160-160l-0.032-0.032l-31.968-31.968l-0.032-0.032l-127.968-128
                c-6.24-6.24-16.384-6.24-22.624,0c-6.24,6.24-6.24,16.384,0,22.624l100.672,100.704h-9.376c-17.952,0-32,14.048-32,32v128
                c0,17.952,14.048,32,32,32h74.368L325.98,444.535c2.912,2.304,6.464,3.488,10.016,3.488c2.336,0,4.704-0.512,6.944-1.568
                c5.536-2.688,9.056-8.288,9.056-14.432v-57.376l68.672,68.672c3.136,3.136,7.232,4.704,11.328,4.704s8.192-1.568,11.328-4.672
                C449.564,437.111,449.564,426.967,443.324,420.727z M159.996,288.023h-64v-128h41.376l22.624,22.624V288.023z M319.996,398.711
                l-128-102.4v-81.664l128,128V398.711z"
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        )}
      </div>
    </div>
  );
}

export default OpeningPage;