import React from 'react'
import Video3 from "../../assets/AboutUs/aboutUsVideo3.mp4"
const WorldClassTeam = () => {
  const [animation ,setAnimation] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
  
            // Not possible to set it back to false like this:
            setAnimation(true);
            
            // No need to keep observing:
            observer.unobserve(domRef.current);
          }
    });

    observer.observe(domRef.current);

   // return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div className='flex gap-20 flex-col md:flex-row-reverse items-center justify-center  m-10 font-Marcellus aboutUsMt '>
       <div className='w-[70%] md:w-full aboutUsVideo flex items-center justify-center'>
    <video className='md:pt-12'   disablePictureInPicture  autoPlay loop muted webkit-playsinline playsInline>
          <source src={Video3} type="video/mp4"></source>
        </video>
        </div>
        <div ref={domRef} className={`${animation ? "fade-in-text" : "opacity-0"} mt-[-50px] md:mt-10 md:ml-10 fixed-width`} >
      <h2 className='flex flex-col md:flex-row items-center text-[15px] md:text-[50px] mobView-header' style={{justifyContent:"right"}}>Our World-class Team</h2>
      <p className=' mt-5 text-right  mobView '>Our team is a synergy of visionary designers, skilled craftsmen, and
dedicated professionals, each contributing their unique expertise to
every project. From conceptualization to completion, we blend
creativity, technical proficiency, and client-focused service to deliver
exceptional results.
</p>
<br/>
<p className=' text-right  mobView '>Our designers bring visions to life, crafting spaces that reflect
individuality and inspire awe. Our craftsmen  meticulously shape materials into works of art, ensuring each piece meets the highest
standards of quality and beauty. And our client  relations team fosters meaningful connections, ensuring your experience with Orshana is
exceptional.
</p>
<br/>
<p className=' text-right  mobView '>Together, we are committed to realizing your vision and exceeding
your expectations.  Thank you for <br/> entrusting  us with your dreams.
</p>
</div>
    </div>
  )
}

export default WorldClassTeam