import React, { useState, useEffect, useRef } from "react";
import logo from '../../assets/images/3.png'
import arrow from '../../assets/images/arrow-down.png'
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../NavbarSeparate/Navbar";
import Hamburger from "../NavbarSeparate/Hamburger";

function MainHomeComponent() {
    const navigate = useNavigate();
    const  location = useLocation();
    const [animation ,setAnimation] = React.useState(false);

  const domRef = React.useRef();
   
      const ref = useRef(null);
      const handleClick = () => {
        window.scrollTo({
            top: 790,
            behavior: 'smooth',
        });
      };
      React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
      
                // Not possible to set it back to false like this:
                setAnimation(true);
                
                // No need to keep observing:
                observer.unobserve(domRef.current);
              }
        });
    
        observer.observe(domRef.current);
   
       // return () => observer.unobserve(domRef.current);
      }, []);
    return (

        <>
        <Navbar/>
            <div  ref={ domRef }  className={`background-image-container bg-cover bg-center w-full h-screen hidden md:flex flex-col justify-center items-center  ${animation ? "fade-in-text" : "opacity-0"}`}>
            <div class=" absolute inset-0 bg-gradient-to-b from-black/80 to-transparent"></div>
                <div className="flex justify-center items-center w-full md:w-3/4 lg:w-2/4 mt-[10px]" onClick={()=>navigate("/")}>
                    <img className="w-2/4 md:w-60 z-10" src={logo} alt="logo orshana" />
                </div>
                <div className={`flex flex-wrap w-full justify-center pt-2 md:pt-3 mt-12 md:mt-8 z-10 cursor-pointer ${animation ? "fade-in-text" : "opacity-0"}`}>
                    <div className="text-white mx-2 md:mx-4 lg:mx-8 xl:mx-12 2xl:mx-20 my-2 md:my-0 p-2 rounded-lg hover:bg-[#dde0d742] hover:rounded-lg transition duration-300">CONCEPTS</div>
                    <div className="text-white mx-2 md:mx-4 lg:mx-8 xl:mx-12 2xl:mx-20 my-2 md:my-0 p-2 rounded-lg hover:bg-[#dde0d742] hover:rounded-lg transition duration-300">MATERIALS</div>
                    <div className="text-white mx-2 md:mx-4 lg:mx-8 xl:mx-12 2xl:mx-20 my-2 md:my-0 p-2 rounded-lg hover:bg-[#dde0d742] hover:rounded-lg transition duration-300" onClick={()=>{navigate("/aboutUs")}}>ABOUT US</div>
                    <div className="text-white mx-2 md:mx-4 lg:mx-8 xl:mx-12 2xl:mx-20 my-2 md:my-0 p-2 rounded-lg hover:bg-[#dde0d742] hover:rounded-lg transition duration-300">SHOWROOM</div>
                    <div className="text-white mx-2 md:mx-4 lg:mx-8 xl:mx-12 2xl:mx-20 my-2 md:my-0 p-2 rounded-lg hover:bg-[#dde0d742] hover:rounded-lg transition duration-300" onClick={()=>{navigate("/contactUs")}}>CONTACT US</div>
                </div>
                
                <div className="mt-10 md:mt-20 flex flex-col items-center text-white z-10">
                    
                    <div className="text-[18px] md:text-[50px] font-Marcellus text-center">Elegance Made Tangible</div>
                    <button className="border border-white rounded-full px-8 py-3 mt-14  text-lg md:text-xl" onClick={handleClick}>Know More</button>
                    <div className="flex flex-col  justify-end h-[150px] ml-10" onClick={handleClick}>
                    <img className=" w-50 animate-fadeInOut   " src={arrow} alt="logo orshana" />
                    </div> 
                </div>
              
            </div>
            

      
        </>
    );
}

export default MainHomeComponent;