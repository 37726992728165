import React from 'react'
import GoogleMapIntegrate from './GoogleMapIntegrate';
import locationImg from '../../assets/images/image 3.png'
import location from "../../assets/images/location.png"
import map from '../../assets/images/map.png';
import mappointer from '../../assets/images/mappointer.png';
const Location = () => {

    const redirectToMap = ()=>{
        const url="https://www.google.com/maps/search/Orshana+Bespoke+Interiors+101,+T%26G+Avenue+Building+Al+Manara+Street,+SZR,+Dubai,+UAE/@25.1402297,55.2069794,15z/data=!3m1!4b1?entry=ttu";
        window.open(url, "_blank");
    }
    return (
        <>
            <div className="flex justify-center mb-20" >
                {/* <img
                    src={locationImg}
                    alt="Location"
                    className="w-[300px] md:w-2/3 hidden  lg:block "
                />
                 <img
                    src={map}
                    alt="Location"
                    className="w-[300px] md:w-2/3 block hover:scale-110 lg:hidden"
                /> */}
                <GoogleMapIntegrate/>
            </div>
        </>
    )
}

export default Location