import React, { useEffect , lazy ,Suspense } from 'react';
import HeaderComponent from '../components/HomeComponent/HeaderComponent';
import Footer from '../components/FooterComponent/Footer';
import Corner from '../components/CornerComponent/Corner';
import Logo from '../components/LogoComponent/Logo';
import Location from '../components/FooterComponent/Location';
import Designs from '../components/HomeComponent/Designs';
import KnowMoreAbout from '../components/HomeComponent/KnowMoreAbout';
import HeritageAndRefinment from '../components/HomeComponent/HeritageAndRefinment';
import SlideShowOne from '../components/HomeComponent/SlideShowOne';
import SlideshowSecond from '../components/HomeComponent/SlideshowSecond';



const Home = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
        return (
          <>
     
            <HeaderComponent/>
          
            <SlideShowOne/>
<SlideshowSecond/>
            <Corner/>
            <Logo/>
            <HeritageAndRefinment/>
            <KnowMoreAbout/>
            <Designs/>
            <Location/>
            <Footer/> 
      </>
      );
}

export default Home
