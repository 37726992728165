import React, { useState } from 'react'
import Flagship from '../../assets/images/image 1.png'
import Studio from '../../assets/images/Frame 15.png'


const Designs = () => {
    const [animation ,setAnimation] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
    
              // Not possible to set it back to false like this:
              setAnimation(true);
              
              // No need to keep observing:
              observer.unobserve(domRef.current);
            }
      });
  
      observer.observe(domRef.current);
  
     // return () => observer.unobserve(domRef.current);
    }, []);
  
  return (
    <>
            <div   ref={ domRef } className="flex justify-center items-center mt-10 md:mt-40" >
                <div className={`text-center text-[18px] md:text-[50px] font-Marcellus ${animation ? "fade-in-text" : "opacity-0"}`}>
                    <div>Come experience Our</div>
                    <div>Artistry in Design.</div>
                </div>
            </div>
            <div class="flex gap-5 justify-center flex-col md:flex-row item-center md:items-end mb-16 mt-[4rem] md:mt-[8rem]">
                <div class="flex flex-col items-center ">
                    <img src={Flagship} alt="flagship store" className={`w-64 md:w-[377px] h-84 md:h-[496px]  object-cover mb-2 ${animation ? "zoom-in-out-box  scale-x-95 scale-y-95" : ""} `} />
                    <div className="text-center text-[18px] md:text-[50px] font-Marcellus">Flagship<br/>Store</div>
                    <button className="text-black py-2 px-10 rounded-full  border-2 text-[18px] font-BritannySignature">
                        Book a Visit
                    </button>

                </div>
                <div class="flex flex-col items-center ">
                <img src={Studio} alt="flagship store" className={`w-64 md:w-[377px] h-84 md:h-[496px]  object-cover mb-2 ${animation ? "zoom-in-out-box  scale-x-95 scale-y-95" : ""} `} />
                    <div className="text-center text-[18px] md:text-[50px] font-Marcellus">Flagship<br/>Store</div>
                    <button className="text-[#BEAA8E] text-[30px] md:text-[30px]   px-4  font-BritannySignature">
                        See More
                    </button>

                </div>
            </div>

        </>
    )
}

export default Designs