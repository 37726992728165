import React, { useEffect, useState } from 'react'
import corner from "../../assets/images/corner.jpg";

const Corner = (props) => {
    const [animation ,setAnimation] = useState(false);
    const domRef = React.useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
      
                // Not possible to set it back to false like this:
                setAnimation(true);
                
                // No need to keep observing:
                observer.unobserve(domRef.current);
              }
        });
    
        observer.observe(domRef.current);
    
       // return () => observer.unobserve(domRef.current);
      }, []);
    
    return (
        <div ref={ domRef } className='mt-[2em] md:mt-[8rem]'  >
             <p className='flex justify-center items-center mt-[5em] text-[#BEAA8E] text-[8px] md:text-[20px]'>SPACES & PLACES</p>
            <div className='flex justify-center items-center text-[18px] md:text-[50px] mt-4'>
            <span className={`text-center font-Marcellus  ${animation ? "fade-in-text" : "opacity-0"} `}>
            <h1>Every corner tells a story...
                <br/>
                Discover Bespoke Interiors
                <br/>
                with Orshana</h1>
           
            </span>
            </div>
            <div className='flex justify-center items-center mt-[50px]'>
            <img className={`w-[60%]    ${animation ? "zoom-in-out-box  scale-x-95 scale-y-95" : ""}`} src={corner} alt="corner" />
            </div>
        </div>
      )
    }


export default Corner
