import React from 'react'

const Timeless = () => {
    const  video4 = "https://firebasestorage.googleapis.com/v0/b/orshana-images.appspot.com/o/video4.MOV?alt=media&token=13c3c18f-dd80-42b5-89c5-2518ef8e120e";
    const [animation ,setAnimation] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
    
              // Not possible to set it back to false like this:
              setAnimation(true);
              
              // No need to keep observing:
              observer.unobserve(domRef.current);
            }
      });
  
      observer.observe(domRef.current);
  
     // return () => observer.unobserve(domRef.current);
    }, []);
    return (
 <div className='flex justify-center'>
  <div className='flex gap-5 flex-col md:flex-row  font-Marcellus m-10 fixed-width-1700 md:mt-[-2rem]'> 
    <div ref={domRef} className={` ${animation ? "fade-in-text" : "opacity-0"} flex flex-col justify-center items-center `} >
    <h2 className='flex flex-col md:flex-row items-center justify-center  text-[18px] md:text-[50px] mobView-header '>Our Craftsmanship</h2>
      <p className='mt-5 text-justify mobView '>Our master craftsmen combine traditional techniques with new
technologies to create timeless, high quality items. We are committed
to representing traditional practices in an ever-changing modern
environment.</p>
<br/>
<p className='text-justify mobView'>These designs are suitable for contract projects which, at the same time,
provide the necessary warmth in home environments. We can find them
in exclusive residential areas or in the best hotels and offices. We have
also had the opportunity to transfer our essence to restaurants, shops,
yachts, hospitals, schools and clubs, among others.
</p>
</div>
    <div ref={domRef} className={` ${animation ? "fade-in-text" : "opacity-0"} flex flex-col justify-center items-center `} >
      <h2 className='flex flex-col md:flex-row items-center justify-center  text-[18px] md:text-[50px] mobView-header '>Timeless designs for living</h2>
      <p className='mt-5 xt-justify mobView'>The success of Orshana’s design comes from the hand of prestigious
international designers. We are very proud to have all of them and their
timeless designs that perfectly reflect our philosophy.</p>
<br/>
<p className=' text-justify mobView'>These designs are suitable for contract projects which, at the same time,
provide the necessary warmth in home environments. We can find them
in exclusive residential areas or in the best hotels and offices. We have
also had the opportunity to transfer our essence to restaurants, shops,
yachts, hospitals, schools and clubs, among others.
</p>
</div>
</div>
 </div>
  )
}

export default Timeless