import React from 'react'
import AboutUs from '../layouts/AboutUs';

const AboutUsPage = () => {
  return (
<AboutUs />
  )
}

export default AboutUsPage
