import React from 'react'
import { useState , useEffect , useRef } from 'react';
import logo from '../../assets/images/5.png'
import { useLocation, useNavigate } from "react-router-dom";
const Hamburger = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    useEffect(() => {
        function handleClickOutside(event) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
             setSidebarOpen(false);
            }
          }
      
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
          };

      }, [sidebarRef]);
      if (pathname==="/") {
        return null; // Hide the navigation bar and sidebar for the home page
      }

      const onRedirect = (linkTo)=>{
        setSidebarOpen(false);
        navigate(linkTo)
      }
      
  return (
   <div>
         <div>
                <nav class="bg-[#ffffff] h-10 fixed top-0 left-0 w-full  z-50 md:hidden">
                    <div class="max-w-7xl mx-auto px-4 flex justify-between items-center">
                        <div class="flex items-center justify-center flex-grow  ">
                            <div className="" onClick={() => {setSidebarOpen(!sidebarOpen)}}>
                                <svg  id="&#x421;&#x43B;&#x43E;&#x439;_1" className="w-5 mt-3" enable-background="new 0 0 4000 4000" viewBox="0 0 4000 4000" xmlns="http://www.w3.org/2000/svg"><g fill="#333"><path d="m3040 1870h-2080c-71.792 0-130 58.208-130 130s58.208 130 130 130h2080c71.792 0 130-58.208 130-130s-58.208-130-130-130z" /><path d="m3040 960h-2080c-71.792 0-130 58.208-130 130s58.208 130 130 130h2080c71.792 0 130-58.208 130-130s-58.208-130-130-130z" /><path d="m3040 2780h-2080c-71.792 0-130 58.208-130 130s58.208 130 130 130h2080c71.792 0 130-58.208 130-130s-58.208-130-130-130z" /></g></svg>
                            </div>
                            <div class="flex items-center justify-center flex-grow" onClick={()=>onRedirect("/home")}>
                                <img class="w-[90px]  mr-5 invert " src={logo} alt="logo orshana" />
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                ref={sidebarRef}
                className={`fixed bg-white  w-40 h-full z-30 top-10 transform transition-transform ease-in-out ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
                    }`}
            >
                    <div className=" mx-2 my-4 md:my-0 cursor-pointer" onClick={()=>onRedirect("/home")}>HOME</div>
                    <div className=" mx-2 my-4 md:my-0 cursor-pointer">CONCEPTS</div>
                    <div className=" mx-2 my-4 md:my-0 cursor-pointer">MATERIALS</div>
                    <div className=" mx-2 my-4 md:my-0 cursor-pointer" onClick={()=>onRedirect("/aboutUs")}>ABOUT US</div>
                    <div className=" mx-2 my-4 md:my-0 cursor-pointer">SHOWROOM</div>
                    <div className=" mx-2 my-4 md:my-0 cursor-pointer" onClick={()=>onRedirect("/contactUs")}>CONTACT US</div>
                <div className="p-4">
                   
                </div>
            </div>
    </div>
  )
}

export default Hamburger
