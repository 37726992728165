import React from "react";
import facebooklogo from '../../assets/contactus/Facebook_black.png'
import instalogo from '../../assets/contactus/Instagram_black.png'
import whatsuplogo from '../../assets/contactus/WhatsApp_black.png'
import weblogo from '../../assets/contactus/webLogo.png'
import threadlogo from '../../assets/contactus/Vector.png'

const Contactoptions = () => {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-20 m-4 ml-[25%] md:ml-0 text-center md:text-left font-Marcellus">
      <div className="flex flex-row gap-2">
        {/* <img
            src={facebooklogo}
            alt="Location"
            className="w-6 md:w-4 lg:w-6 hidden lg:block"
          />  */}
          
          <img
            src={instalogo}
            alt="Location"
            className="w-6 md:w-4 lg:w-6  lg:block"
          /><a href="https://www.instagram.com/orshanadesigns/" target="_blank" rel="noopener noreferrer">orshanadesigns</a></div>
      <div className="flex flex-row gap-2">
        <img
            src={whatsuplogo}
            alt="Location"
            className="w-6 md:w-4 lg:w-6  lg:block"
          />+971 (0) 4 3213 615</div>
      <div className="flex flex-row gap-2">
        <img
            src={weblogo}
            alt="Location"
            className="w-6 md:w-4 lg:w-6  lg:block"
          />www.orshana.com</div>
      <div className="flex flex-row gap-2 ">
        <img
            src={threadlogo}
            alt="Location"
            className="w-6 md:w-4 lg:w-6  lg:block bg-black p-0.5 rounded-full"
          />hello@orshana.com</div>
    </div>
  );
};

export default Contactoptions;
